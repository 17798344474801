import React, { useState } from 'react';

import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';
import { DonateIcon, EarphonesIcon, IsntagramIcon, NewsIcon, ProjectIcon, YoutubeIcon } from '../../utils/IconUtils';

const Footer = () => {

    const [hover, setHover] = useState(null);

    const footerData = [
        {
            id: 0,
            icon: (props) => <EarphonesIcon {...props} />,
            title: "Bize Ulaşın",
            href: "/contact"
        },
        {
            id: 1,
            icon: (props) => <ProjectIcon {...props} />,
            title: "Projelerimiz",
            href: "/projects"
        },
        {
            id: 2,
            icon: (props) => <NewsIcon {...props} />,
            title: "Haberler",
            href: "/news"
        },
        {
            id: 3,
            icon: (props) => <DonateIcon {...props} />,
            title: "Bağışlar",
            href: "/donations"
        }
    ]

    return (
        <div className={styles.footerContainer}>
            <div className={styles.footerMainContainer}>
                <div className={styles.footerTopContainer}>
                    {
                        footerData.map(val => (
                            <Link 
                                style={{ textDecoration: "none" }}
                                to={val.href}
                                onMouseEnter={() => setHover(val.id)} 
                                onMouseLeave={() => setHover(null)}
                                className={styles.footerTopItem}
                            >
                                <div>{val.icon({ color: hover === val.id ? "#3288A4" : "#828080" })}</div>
                                <span style={{ color: hover === val.id ? "#3288A4" : "#828080" }} >{val.title}</span>
                            </Link>
                        ))
                    }
                </div>
                <div className={styles.footerContentContainer}>
                    <div style={{ paddingRight: "24px" }} className={styles.footerItem}>
                        <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil quod cupiditate a incidunt illo molestias unde consectetur exercitationem ab, sit accusamus repellat corporis debitis.
                        </p>
                    </div>
                    <div className={styles.footerItem}>
                        <div>
                            <h3>
                                E-MAIL
                            </h3>
                            <Link style={{ textDecoration: "none" }} className={styles.itemLink} to={"#"}>
                                info.loremipsum@gmail.com
                            </Link>
                        </div>
                        <div>
                            <h3>
                                TEL
                            </h3>
                            <Link style={{ textDecoration: "none" }} className={styles.itemLink} to={"#"}>
                                +905555555555
                            </Link>
                        </div>
                    </div>
                    <div className={styles.footerItem}>
                        <h2 className={styles.menuTitle}>MENÜLER</h2>
                        <Link style={{ textDecoration: "none" }} className={styles.itemLink} to={"/about-us"}>
                            Hakkımızda
                        </Link>
                        <Link style={{ textDecoration: "none" }} className={styles.itemLink} to={"/projects"}>
                            Projelerimiz
                        </Link>
                        <Link style={{ textDecoration: "none" }} className={styles.itemLink} to={"/news"}>
                            Haberler
                        </Link>
                        <Link style={{ textDecoration: "none" }} className={styles.itemLink} to={"/donations"}>
                            Bağışlar
                        </Link>
                        <Link style={{ textDecoration: "none" }} className={styles.itemLink} to={"/account-numbers"}>
                            Hesap numaraları
                        </Link>
                        <Link style={{ textDecoration: "none" }} className={styles.itemLink} to={"/contact"}>
                            İletişim
                        </Link>
                    </div>
                    <div className={styles.footerItem}>
                        <h2 className={styles.menuTitle}>BİZİ TAKİP EDİN</h2>
                        <div style={{display: "flex", gap: "8px"}}>
                            <div>
                                <YoutubeIcon />
                            </div>
                            <div>
                                <IsntagramIcon />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer