import React from 'react';

import styles from './NewsDetailPage.module.scss';

const NewsDetailPage = () => {
    return (
        <div className={styles.newsDetailPage}>
            <div className={styles.titleContainer}>
                <h2>Lorem ipsum</h2>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentMainContainer}>
                    <div className={styles.imageContainer}>
                        <img src="https://picsum.photos/1320/400" alt="" />
                    </div>
                    <div className={styles.textContainer}>
                        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error, id enim. Aspernatur veritatis id ex consequuntur. Doloribus nisi a, impedit consectetur assumenda quo aperiam repellendus pariatur cumque ipsam saepe perspiciatis!
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error, id enim. Aspernatur veritatis id ex consequuntur. Doloribus nisi a, impedit consectetur assumenda quo aperiam repellendus pariatur cumque ipsam saepe perspiciatis!
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error, id enim. Aspernatur veritatis id ex consequuntur. Doloribus nisi a, impedit consectetur assumenda quo aperiam repellendus pariatur cumque ipsam saepe perspiciatis!
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error, id enim. Aspernatur veritatis id ex consequuntur. Doloribus nisi a, impedit consectetur assumenda quo aperiam repellendus pariatur cumque ipsam saepe perspiciatis!
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error, id enim. Aspernatur veritatis id ex consequuntur. Doloribus nisi a, impedit consectetur assumenda quo aperiam repellendus pariatur cumque ipsam saepe perspiciatis!
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error, id enim. Aspernatur veritatis id ex consequuntur. Doloribus nisi a, impedit consectetur assumenda quo aperiam repellendus pariatur cumque ipsam saepe perspiciatis!
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error, id enim. Aspernatur veritatis id ex consequuntur. Doloribus nisi a, impedit consectetur assumenda quo aperiam repellendus pariatur cumque ipsam saepe perspiciatis!
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error, id enim. Aspernatur veritatis id ex consequuntur. Doloribus nisi a, impedit consectetur assumenda quo aperiam repellendus pariatur cumque ipsam saepe perspiciatis!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsDetailPage