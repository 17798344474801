import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './NewsCards.module.scss';

const NewsCard = ({ data }) => {

  const navigate = useNavigate();

  return (
      <div onClick={() => navigate('/project-detail')} key={data.id} className={styles.newsCardContainer}>
        <div className={styles.newsMainCardContainer}>
          <div className={styles.imageContainer}>
            <img src={data.img} alt="" />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.textContainer}>
              <h2>{data.title}</h2>
              <p>{data.content}</p>
            </div>
            <div className={styles.dateContainer}>
              <span>{data.date}</span>
            </div>
          </div>
        </div>
      </div>
  )
}

export default NewsCard;