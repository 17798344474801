import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './components/header/Header';
import Loading from './components/loading/Loading';
import Footer from './components/footer/Footer';

const SANLayout = () => {

    const { pathname } = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 1200);
        
    },[pathname])

    if(loading) return <Loading />

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default SANLayout;