import React from 'react';
import AccountCard from '../../components/accountCard/AccountCard';

import styles from './AccountNumbersPage.module.scss';

const AccountNumbersPage = () => {

    const accountData = [
        {
            id: 0,
            image: require('../../static/vakif-bank.png'),
            branchName: "Vakıf Bank / Lorem ipsum",
            swiftCode: "0000000AXXX",
            accountName: "LOREM IPSUM IPS LOREM IPSUML",
            ibanData: [
                {
                    id: 0,
                    type: "TL",
                    no: "12345678-5001",
                    iban: "TR00 0000 0000 0000 0000 0000 00"
                }
            ]
        },
        {
            id: 1,
            image: require('../../static/vakif-katilim.png'),
            branchName: "Vakıf Katılım / Lorem ipsum",
            swiftCode: "0000000AXXX",
            accountName: "LOREM IPSUM IPS LOREM IPSUML",
            ibanData: [
                {
                    id: 0,
                    type: "TL",
                    no: "12345678-5001",
                    iban: "TR00 0000 0000 0000 0000 0000 00"
                }
            ]
        },
        {
            id: 2,
            image: require('../../static/ziraat-bank.png'),
            branchName: "Ziraat Bankası / Lorem ipsum",
            swiftCode: "0000000AXXX",
            accountName: "LOREM IPSUM IPS LOREM IPSUML",
            ibanData: [
                {
                    id: 0,
                    type: "Türk Lirası",
                    no: "12345678-5001",
                    iban: "TR00 0000 0000 0000 0000 0000 00"
                },
                {
                    id: 1,
                    type: "Dolar",
                    no: "12345678-5001",
                    iban: "TR00 0000 0000 0000 0000 0000 00"
                },
                {
                    id: 2,
                    type: "Euro",
                    no: "12345678-5001",
                    iban: "TR00 0000 0000 0000 0000 0000 00"
                }
            ]
        }
    ]
    return (
        <div className={styles.accountNumbersPageContainer}>
            <div className={styles.titleContainer}>
                <h2>Hesap Numaraları</h2>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentMainContainer}>
                    {
                        accountData.map(val => (
                            <AccountCard key={val.id} data={val} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default AccountNumbersPage;