import React from 'react';

import styles from './ContactPage.module.scss';
import { Link } from 'react-router-dom';
import { EarphonesIcon, MailIcon } from '../../utils/IconUtils';

const ContactPage = () => {
    return (
        <div className={styles.contactPageContainer}>
            <div className={styles.titleContainer}>
                <h2>İletişim</h2>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentMainContainer}>
                    <div className={styles.mailPhoneContainer}>
                        <div className={styles.topItem}>
                            <div className={styles.textContainer}>
                                <h5 style={{ display: "grid", justifyContent: "flex-end"}}>Bize mail gönderin</h5>
                                <Link style={{ textDecoration: "none" }} className={styles.itemLink} to={"mailto:info@loremipsum.com"}>
                                    info@loremipsum.com
                                </Link>
                            </div>
                            <div className={styles.iconContainer}>
                                <MailIcon />
                            </div>
                        </div>
                        <div className={styles.topItem}>
                            <div className={styles.iconContainer}>
                                <EarphonesIcon />
                            </div>
                            <div className={styles.textContainer}>
                                <h5>Bize telefon ile ulaşın</h5>
                                <Link style={{ textDecoration: "none" }} className={styles.itemLink} to={"tel:+905555555555"}>
                                    +90 555 55 55 55
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <iframe style={{ border: "none" }} title='map' src="https://maps.google.com/maps?q=41.03486445721597,28.93470620789526&output=embed&z=21" width="100%" height="500" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className={styles.contactFormContainer}>
                        <div className={styles.topContainer}>
                            <input type="text" placeholder="Ad Soyad" />
                            <input type="text" placeholder="Telefon" />
                            <input type="text" placeholder="E-Posta" />
                        </div>
                        <div className={styles.bottomContainer}>
                            <textarea type="text" placeholder="Not" />
                        </div>
                        <button className={styles.formButton}>
                            <span>
                                Mesajı Gönder
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactPage