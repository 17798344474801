import React, { useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { CloseIcon } from '../../utils/IconUtils';

import colors from '../../_export.scss'
import styles from './Modal.module.scss';

const Modal = ({ open, closeFunction, title, children, backgroundColor }) => {

    const modalRef = useRef(null);

    const modalSlide = {
        initial: {
            y: "-100%",
            transition: {duration: 0.6, ease: [0.76, 0, 0.24, 1]},
            opacity: 0
        },
        enter: {
            y: "0%",
            transition: {duration: 0.6, ease: [0.76, 0, 0.24, 1]},
            opacity: 1
        },
        exit: {
            y: "-100%",
            transition: {duration: 0.6, ease: [0.76, 0, 0.24, 1]},
            opacity: 0
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeFunction();
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, closeFunction]);

    return (
        <>
            <AnimatePresence mode='wait'>
                {
                    open
                    ?
                        <div className={styles.modalContainer}>
                            <motion.div 
                                ref={modalRef}
                                className={styles.modalMainContainer}
                                variants={modalSlide} 
                                animate="enter" 
                                exit="exit" 
                                initial="initial" 
                            >
                                <div style={{ backgroundColor: backgroundColor ?? colors.gray}} className={styles.titleContainer}>
                                    <span className={styles.title}>{title && title}</span>
                                    <div onClick={() => closeFunction()} className={styles.closeIconContainer}>
                                        <CloseIcon />
                                    </div>
                                </div>
                                <div className={styles.contentContainer}>
                                    {children}
                                </div>
                            </motion.div>
                        </div>
                    :
                        null
                }
            </AnimatePresence>
        </>
    )
}

export default Modal