import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SANLayout from './SANLayout';
import HomePage from './pages/homePage/HomePage';
import AboutUsPage from './pages/aboutUsPage/AboutUsPage';
import ProjectsPage from './pages/projectsPage/ProjectsPage';
import NewsPage from './pages/newsPage/NewsPage';
import DonationsPage from './pages/donationsPage/DonationsPage';
import AccountNumbersPage from './pages/accountNumbersPage/AccountNumbersPage';
import ContactPage from './pages/contactPage/ContactPage';
import ProjectDetailPage from './pages/projectDetailPage/ProjectDetailPage';
import NewsDetailPage from './pages/newsDetailPage/NewsDetailPage';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route element={<SANLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/project-detail" element={<ProjectDetailPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news-detail" element={<NewsDetailPage />} />
          <Route path="/donations" element={<DonationsPage />} />
          <Route path="/account-numbers" element={<AccountNumbersPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRoutes;