import React from 'react';

import styles from './DonationCard.module.scss';

const DonationCard = ({ data }) => {
    return (
        <div className={styles.donationCardContainer}>
            <div className={styles.donationMainCardContainer}>
                <div className={styles.imageContainer}>
                  <img src={data.img} alt="" />
                </div>
                <div className={styles.contentContainer}>
                  <div className={styles.textContainer}>
                    <h2>{data.title}</h2>
                    <p>{data.content}</p>
                  </div>
                  <div className={styles.dateContainer}>
                    <button className={styles.donationButton}>
                        <span>
                            Bağış Yap
                        </span>
                    </button>
                    {/* <div className={styles.inputContainer}>
                        <input type="text" placeholder="Tutar Giriniz.." />
                        <div className={styles.symbolContainer}>
                            ₺
                        </div>
                    </div> */}
                  </div>
                </div>
            </div>
        </div>
    )
}

export default DonationCard