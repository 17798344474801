import React, { useState } from 'react';
import DonationCard from '../../components/donationCard/DonationCard';
import { WaterIcon } from '../../utils/IconUtils';

import styles from './DonationsPage.module.scss';

const DonationsPage = () => {

    const [hover, setHover] = useState(null);
    const [selectedBoxId, setSelectedBoxId] = useState(0);

    const newsData = [
        {
            id: 0,
            title: "Lorem ipsum dolor sit",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 0
        },
        {
            id: 1,
            title: "Lorem ipsum dolor sit",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 0
        },
        {
            id: 2,
            title: "Lorem ipsum dolor sit",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 0
        },
        {
            id: 3,
            title: "Lorem ipsum dolor sit",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 1
        },
        {
            id: 4,
            title: "Lorem ipsum dolor sit",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 1
        },
        {
            id: 5,
            title: "Lorem ipsum dolor sit",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 3
        },
        {
            id: 6,
            title: "Lorem ipsum dolor",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 3
        },
        {
            id: 7,
            title: "Lorem ipsum dolor sit",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 4
        },
        {
            id: 8,
            title: "Lorem ipsum dolor sit",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 5
        },
        {
            id: 9,
            title: "Lorem ipsum dolor sit",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 0
        },
        {
            id: 10,
            title: "Lorem ipsum dolor sit",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/news-details",
            type: 2
        },
    ]

    const filterData = [
        {
            id: 0,
            title: "Lorem ipsum",
            icon: (props) => <WaterIcon {...props} />
        },
        {
            id: 1,
            title: "Lorem ipsum",
            icon: (props) => <WaterIcon {...props} />
        },
        {
            id: 2,
            title: "Lorem ipsum",
            icon: (props) => <WaterIcon {...props} />
        },
        {
            id: 3,
            title: "Lorem ipsum",
            icon: (props) => <WaterIcon {...props} />
        },
        {
            id: 4,
            title: "Lorem ipsum",
            icon: (props) => <WaterIcon {...props} />
        },
        {
            id: 5,
            title: "Lorem ipsum",
            icon: (props) => <WaterIcon {...props} />
        },
        {
            id: 6,
            title: "Lorem ipsum",
            icon: (props) => <WaterIcon {...props} />
        },
        {
            id: 7,
            title: "Lorem ipsum",
            icon: (props) => <WaterIcon {...props} />
        },
        {
            id: 8,
            title: "Lorem ipsum",
            icon: (props) => <WaterIcon {...props} />
        },
        {
            id: 9,
            title: "Lorem ipsum",
            icon: (props) => <WaterIcon {...props} />
        }
    ]

    return (
        <div className={styles.donationsPageContainer}>
            <div className={styles.titleContainer}>
                <h2>Bağışlar</h2>
            </div>
            <div className={styles.filterContainer}>
                <div className={styles.filterMainContainer}>
                    {
                        filterData.map(val => (
                            <div 
                                onMouseEnter={() => setHover(val.id)} 
                                onMouseLeave={() => setHover(null)}
                                className={styles.filterCard}
                                onClick={() => setSelectedBoxId(val.id)}
                                style={{ marginTop: selectedBoxId === val.id ? "-12px" : "0px"}}
                            >
                                <div className={styles.iconContainer}>
                                    {val.icon({ color: hover === val.id || selectedBoxId === val.id ? "#3288A4" : "#2B363D" })}
                                </div>
                                <span style={{ color: hover === val.id || selectedBoxId === val.id ? "#3288A4" : "#2B363D" }}>{val.title}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentMainContainer}>
                    {
                        newsData.filter((item) => item.type === selectedBoxId).map((val) => (
                            <DonationCard key={val.id} data={val} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default DonationsPage