import React from 'react';
import NewsCard from '../../components/newsCard/NewsCards';
import InfoCard from '../../components/infoCard/InfoCard';
import Slider from 'react-slick';
import ProjectSection from '../../components/projectSection/ProjectSection';
import MoneyImage from '../../static/saving.png';

import styles from './HomePage.module.scss';

const HomePage = () => {

  const sliderItems = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ]

  const settings = {
    fade: false,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    waitForAnimate: false,
    arrows: false,
  };

  const newsSettings = {
    fade: false,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    waitForAnimate: false,
    arrows: false,
    sliderItems: 3,
    dots: true,
    responsive: [
      {
          breakpoint: 1070,
          settings: {
              dots: false,
              infinite: true,
              speed: 500,
              slidesToShow: 2,
          }
      },
      {
          breakpoint: 740,
          settings: {
              dots: false,
              infinite: true,
              speed: 500,
              slidesToShow: 1,
          }
      },
  ]
  };

  const newsData = [
    {
      id: 0,
      title: "Lorem ipsum dolor sit amet adipisicing similique.",
      content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
      date: "30.09.2024",
      img: "https://picsum.photos/410/220"
    },
    {
      id: 1,
      title: "Lorem ipsum dolor sit amet adipisicing similique.",
      content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
      date: "30.09.2024",
      img: "https://picsum.photos/410/220"
    },
    {
      id: 2,
      title: "Lorem ipsum dolor sit amet adipisicing similique.",
      content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
      date: "30.09.2024",
      img: "https://picsum.photos/410/220"
    },
    {
      id: 3,
      title: "Lorem ipsum dolor sit amet adipisicing similique.",
      content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
      date: "30.09.2024",
      img: "https://picsum.photos/410/220"
    },
  ]

  const infoData = [
    {
      id: 0,
      title: "Lorem ipsum",
      color: "#EA615C",
      href: ""
    },
    {
      id: 1,
      title: "Lorem ipsum",
      color: "#3288A4",
      href: ""
    },
    {
      id: 2,
      title: "Lorem ipsum dolor",
      color: "#EAAE5C",
      href: ""
    },
    {
      id: 3,
      title: "Lorem ipsum",
      color: "#32A444",
      href: ""
    },
    {
      id: 4,
      title: "Lorem ipsum",
      color: "#EA615C",
      href: ""
    },
    {
      id: 5,
      title: "Lorem ipsum dolor",
      color: "#3288A4",
      href: ""
    },
    {
      id: 6,
      title: "Lorem ipsum dolor",
      color: "#EAAE5C",
      href: ""
    },
    {
      id: 7,
      title: "Lorem ipsum",
      color: "#32A444",
      href: ""
    }
  ]

  return (
    <div className={styles.homePageContainer}>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderMainContainer}>
          <Slider {...settings} slidesToShow={1} dots={false}>
            {
              sliderItems.map((val) => (
                <div key={val.id} className={styles.sliderCardContainer}>
                  <div className={styles.sliderCardLeftContainer}>
                    <div className={styles.contentContainer}>
                      <h2>Lorem ipsum, dolor sit amet</h2>
                      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsam vero veniam sequi cum pariatur ab, itaque eius illum temporibus! Odio adipisci optio deserunt sequi! Harum temporibus maiores ab quis similique!</p>
                    </div>
                    <div className={styles.buttonContainer}>
                      <button className={styles.infoButton}>
                        <span>
                          Detaylı Bilgi
                        </span>
                      </button>
                      <button className={styles.donationButton}>
                        <span>
                            Bağış Yap
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className={styles.imgContainer}>
                    <img src={"https://picsum.photos/490/350"} alt="" />
                  </div>
                </div>
              ))
            }
          </Slider>
        </div>
      </div>
      <div className={styles.newsContainer}>
        <div className={styles.newsMainContainer}>
          <div className={styles.titleContiner}>
            <h2>
              HABERLER
            </h2>
          </div>
          <Slider {...newsSettings} slidesToShow={3} dots={true}>
            {
              newsData.map((val) => (
                <NewsCard key={val.id} data={val} />
              ))
            }
          </Slider>
        </div>
      </div>

      <div className={styles.infoContainer}>
        <div className={styles.infoMainContainer}>
          <div className={styles.titleContiner}>
            <h2>
              BİLGİ EDİN
            </h2>
          </div>
          <div className={styles.infoContentContainer}>
            {
              infoData.map(val => (
                <InfoCard key={val.id} data={val} />
              ))
            }
          </div>
        </div>
      </div>

      <ProjectSection />

      <div className={styles.moneyCardContainer}>
        <div className={styles.moneyCardMainContainer}>
          <div className={styles.leftContent}>
            <h2>Lorem ipsum</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, voluptate corrupti accusantium eveniet perspiciatis.</p>
            <button className={styles.moneyButton}>
              <span>Lorem ipsum</span>
            </button>
          </div>
          <div className={styles.rightContent}>
            <img style={{ width: "100%", height: "100%" }} src={MoneyImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage