import React, { useCallback, useEffect, useState } from 'react';
import Menu from '../menu/Menu';
import { MenuIcon } from '../../utils/IconUtils';
import { Link } from 'react-router-dom';
import SideBar from '../sideBar/SideBar';
import { useNavigate } from 'react-router-dom';
import Modal from '../modal/Modal';
import {useDropzone} from 'react-dropzone'

import styles from './Header.module.scss';

const Header = () => {
    
    const navigate = useNavigate();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false); 
    // const onDrop = useCallback(acceptedFiles => {
    //     // Do something with the files
        
    // }, [])
    // const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const {getRootProps, getInputProps, acceptedFiles} = useDropzone({noDrag: true});
    const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);

    useEffect(() => {
        console.log("files", files);
        
    },[files])
    const menuItems = [
        {
            id: 1,
            title: "Hakkımızda",
            href: "/about-us",
        },
        {
            id: 2,
            title: " Projelerimiz",
            href: "/projects"
        },
        {
            id: 3,
            title: "Haberler",
            href: "/news"
        },
        {
            id: 4,
            title: "Bağışlar",
            href: "/donations"
        },
        {
            id: 5,
            title: "Hesap numaraları",
            href: "/account-numbers"
        },
        {
            id: 6,
            title: "İletişim",
            href: "/contact"
        },
    ] 

    const closeFunction = () => {
        setIsOpenMenu(false);
    }

    const closeModalFunc = () => {
        setIsOpenModal(false);
    }

    return (
        <>
            <div className={styles.headerContainer}>
                <div className={styles.mainContainer}>
                    <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                        <span style={{ fontSize: "32px", fontWeight: 700 }}>DAİMA İYİLİK</span>
                    </div>
                    <div className={styles.menuContainer}>
                        <Menu data={menuItems} />
                    </div>
                    <button onClick={() => setIsOpenModal(true)} className={styles.donationButton}>
                        <span>
                            Bağış Yap
                        </span>
                    </button>
                    <div onClick={() => setIsOpenMenu(true)} className={styles.menuIconContainer}>
                        <MenuIcon />
                    </div>
                </div>
            </div>
            <SideBar 
                open={isOpenMenu} 
                closeFunction={closeFunction}
                title={"MENÜLER"}
                align={"right"}
            >
                <div className={styles.sideMenuContainer}>
                    {
                        menuItems.map((val) => (
                            <div onClick={() => setIsOpenMenu(false)} key={val.id} className={styles.sideMenuItem}>
                                <Link style={{ textDecoration: "none" }} className={styles.item} to={val.href}>
                                    {val.title}
                                </Link>
                            </div>
                        ))
                    }
                    <div onClick={() => setIsOpenMenu(false)} className={styles.sideMenuItem}>
                        <Link className={styles.item} to={"#"}>
                            +90 555 555 55 55
                        </Link>
                    </div>
                    <div onClick={() => setIsOpenMenu(false)} className={styles.sideMenuItem}>
                        <Link className={styles.item} to={"mailto:lorem@loremlorem.com"}>
                            lorem@loremlorem.com
                        </Link>
                    </div>
                </div>
            </SideBar>
            <Modal
                open={isOpenModal} 
                closeFunction={closeModalFunc}
                title={"Bağış Yap"}
            >
                <div className={styles.modalContainer}>
                    <div>
                        <div className={styles.textContainer}>
                            <input type="text" placeholder="Adınız" />
                            <input type="text" placeholder="Soyadınız" />
                        </div>
                        <div className={styles.textContainer}>
                            <input type="number" placeholder="Telefon" />
                            <input type="number" placeholder="Bağış Tutarı" />
                        </div>
                        <div className={styles.dragAndDropArea} {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                files.length > 0 ?
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "48px" }}>
                                        <h4>Dosya:</h4>
                                        <ul>{files}</ul>
                                    </div>
                                :
                                    <p>Buraya bir dosya yükleyin veya bırakın</p> 
                                
                            }
                        </div>
                        <button onClick={() => setIsOpenModal(true)} className={styles.donationButton}>
                            <span>
                                Bağış Yap
                            </span>
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Header