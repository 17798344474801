import React from 'react';
import { Link } from 'react-router-dom';
import { PlayIcon } from '../../utils/IconUtils';

import styles from './InfoCard.module.scss';

const InfoCard = ({ data }) => {
    return (
        <Link 
            key={data.id} 
            style={{ backgroundColor: data.color, textDecoration: "none" }} 
            className={styles.infoCardContainer} 
            to={data.href}
        >
            <span>{data.title}</span>
            <PlayIcon />
        </Link>
    )
}

export default InfoCard;