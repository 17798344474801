import React, { useState } from 'react';
import { LocationIcon, PersonIcon } from '../../utils/IconUtils';
import ProgressBar from "@ramonak/react-progress-bar";
import { Link } from 'react-router-dom';

import styles from './ProjectSection.module.scss';

const ProjectSection = () => {

    const [selectedProject, setSelectedProject] = useState("project1");

    const projectData = [
        {
          id: "project1",
          title: "Proje 1"
        },
        {
          id: "project2",
          title: "Proje 2"
        },
        {
          id: "project3",
          title: "Proje 3"
        },
        {
          id: "project4",
          title: "Proje 4"
        },
        {
          id: "project5",
          title: "Proje 5"
        },
        {
          id: "project6",
          title: "Proje 6"
        },
    ]

    const projectSupportData = [
        {
          id: 0,
          tag: "Lorem ipsum dolor",
          person: 0,
          title: "Lorem ipsum dolor sit",
          content: "",
          min: 0,
          max: 570000,
        }
    ]

    return (
        <div className={styles.projectContainer}>
            <div className={styles.projectMainContainer}>
                <div className={styles.titleContainer}>
                    <h2>PROJE DESTEKLE</h2>
                    <Link className={styles.link} to={"#"}>
                        Tüm Projeler
                    </Link>
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.leftContainer}>
                        {
                            projectData.map(val => (
                                <div key={val.id} onClick={() => setSelectedProject(val.id)} className={`${selectedProject ===val.id && styles.activeLeftContentCard} ${styles.leftContentCard}`}>
                                    <span>
                                        {val.title}
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.projectSupportCardContainer}>
                            <div className={styles.topContainer}>
                                <div className={styles.tagContainer}>
                                    <LocationIcon color={"#3288A4"} />
                                    <span>Afrika Uganda</span>
                                </div>
                                <div className={styles.countContainer}>
                                    <PersonIcon color={"#3288A4"} />
                                    <span>0 kişi katkı sağladı</span>
                                </div>
                            </div>
                            <div className={styles.midContainer}>
                                <Link className={styles.linkTitle} to={"#"}>Sağlık Ocağı Projesi</Link>
                                <p>Bizler Sevinç Ol Derneği Olarak Afrika’da Yemeksizlik, Susuzluk ve Çamurlu Sudan İçmenin Getirmiş Olduğu Hastalıkların Tedavisi İçin Sağlık Ocağı Projemizi Hayata Geçirmek İstiyoruz.</p>
                            </div>
                            <div className={styles.bottomContainer}>
                                <div className={styles.countContainer}>
                                    <span>0₺</span>
                                    <span>570,000₺</span>
                                </div>
                                <ProgressBar completed="20" bgColor='#3288A4' height='16px' labelSize='14px' margin='-10px 0px 10px 0px' />
                                <button className={styles.projectSupportButton}>
                                    <span>
                                        Projeyi Destekle
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className={styles.projectSupportCardContainer}>
                            <div className={styles.topContainer}>
                                <div className={styles.tagContainer}>
                                    <LocationIcon color={"#3288A4"} />
                                    <span>Afrika Uganda</span>
                                </div>
                                <div className={styles.countContainer}>
                                    <PersonIcon color={"#3288A4"} />
                                    <span>0 kişi katkı sağladı</span>
                                </div>
                            </div>
                            <div className={styles.midContainer}>
                                <Link className={styles.linkTitle} to={"#"}>Sağlık Ocağı Projesi</Link>
                                <p>Bizler Sevinç Ol Derneği Olarak Afrika’da Yemeksizlik, Susuzluk ve Çamurlu Sudan İçmenin Getirmiş Olduğu Hastalıkların Tedavisi İçin Sağlık Ocağı Projemizi Hayata Geçirmek İstiyoruz.</p>
                            </div>
                            <div className={styles.bottomContainer}>
                                <div className={styles.countContainer}>
                                    <span>0₺</span>
                                    <span>570,000₺</span>
                                </div>
                                <ProgressBar completed="20" bgColor='#3288A4' height='16px' labelSize='14px' margin='-10px 0px 10px 0px' />
                                <button className={styles.projectSupportButton}>
                                    <span>
                                        Projeyi Destekle
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
              </div>
            </div>
      </div>
    )
}

export default ProjectSection