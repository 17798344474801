import React from 'react';

import styles from './ProjectsPage.module.scss';
import NewsCard from '../../components/newsCard/NewsCards';

const ProjectsPage = () => {

    const newsData = [
        {
            id: 0,
            title: "Lorem ipsum dolor sit amet adipisicing similique.",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/project-detail"
        },
        {
            id: 1,
            title: "Lorem ipsum dolor sit amet adipisicing similique.",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/project-detail"
        },
        {
            id: 2,
            title: "Lorem ipsum dolor sit amet adipisicing similique.",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/project-detail"
        },
        {
            id: 3,
            title: "Lorem ipsum dolor sit amet adipisicing similique.",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/project-detail"
        },
        {
            id: 4,
            title: "Lorem ipsum dolor sit amet adipisicing similique.",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/project-detail"
        },
        {
            id: 5,
            title: "Lorem ipsum dolor sit amet adipisicing similique.",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique alias inventore autem ratione magnam placeat.",
            date: "30.09.2024",
            img: "https://picsum.photos/410/220",
            href: "/project-detail"
        },
    ]

    return (
        <div className={styles.projectsPageContainer}>
            <div className={styles.titleContainer}>
                <h2>Projelerimiz</h2>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentMainContainer}>
                    {
                        newsData.map((val) => (
                            <NewsCard key={val.id} data={val} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ProjectsPage;