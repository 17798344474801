import React, { useState } from 'react';

import styles from './AccountCard.module.scss';
import { CopyIcon } from '../../utils/IconUtils';

const AccountCard = ({ data }) => {

    const [hover, setHover] = useState(null);

    return (
        <div className={styles.accountCardContainer}>
            <div className={styles.topContainer}>
                <div style={{ maxWidth: "160px", height: "auto" }}>
                    <img style={{ width: "100%", height: "auto" }} src={data.image} alt="" />
                </div>
                <div className={styles.topItem}>
                    <span>Şube Adı</span>
                    <h4>{data.branchName}</h4>
                </div>
                <div className={styles.topItem}>
                    <span>Swift Kodu</span>
                    <h4>{data.swiftCode}</h4>
                </div>
                <div className={styles.topItem}>
                    <span>Hesap Adı</span>
                    <h4>{data.accountName}</h4>
                </div>
            </div>
            {
                data.ibanData.map(val => (
                    <div key={val.id} className={styles.bottomContainer}>
                        <div className={styles.bottomItem}>
                            <span>{val.type}</span>
                        </div>
                        <div className={styles.bottomItem}>
                            <span>{val.no}</span>
                        </div>
                        <div className={styles.bottomItem}>
                            <span>{val.iban}</span>
                        </div>
                        <div 
                            onClick={() => navigator.clipboard.writeText("TR00 0000 0000 0000 0000 0000 00")} 
                            onMouseEnter={() => setHover(val.id)} 
                            onMouseLeave={() => setHover(null)} 
                            className={styles.copyButtonContainer}
                        >
                            <div className={styles.copyButton}>
                                <CopyIcon color={hover === val.id ? "#2B363D" : "#BFC2C4" } />
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default AccountCard;